import React, {Component} from "react";

import { Button } from "primereact/button";

const namen = ['Dwight', 'Edske(Freddy H.)', 'Epke', 'Gleuf', 'Goofy', 'Heinz', 'Hubo', 'Jeff', 'Jeltsin', 'Jopie', 'Julien', 'Kees', 'Lee', 'Lucifer', 'Mickey', 'Mitchell', 'Mo', 'Mort', 'Noah', 'Nono', 'OH-HJ', 'Olaf', 'Olivier', 'Peter', 'Pik', 'Ronnie', 'Sjon', 'Steen', 'Stifler', 'Sukke', 'Tejoo K', 'van Rossum', 'Vondel', 'Willie', 'Winston', 'Woody', 'Sanana', 'Sietse']

const importAll = (require:any) =>
    require.keys().reduce((acc:any, next:any) => {
      acc[next.replace("./", "")] = require(next);
      return acc;
    }, {});
  
const images = importAll(
    require.context(".//huismokken", false, /\.(png|jpe?g|svg)$/)
);

export default class Spel extends Component<{},{currentScore:number, highScore:number, options:string[], name:string}>{
    constructor(props:any){
        super(props)
        let randoms = this.randomNames()
        this.state = {
            currentScore: 0,
            highScore: 0,
            options: randoms.knoppen,
            name: randoms.randomnaam
        }
    }

    getRandomInt(max:number) {
        return Math.floor(Math.random() * max);
    }

    randomNames(){
        let randomnaam = namen[this.getRandomInt(namen.length)]
        let i = 0
        let knoppen:string[] = []
        while(i < 4){
            let newknop = namen[this.getRandomInt(namen.length)]
            if(newknop != randomnaam && knoppen.includes(newknop) === false){
                knoppen.push(newknop)
                i += 1
            } 
        }
        knoppen.splice(this.getRandomInt(5), 0, randomnaam)
        return {
            knoppen: knoppen,
            randomnaam: randomnaam
        }
    }

    ronde(keuze:string){
        if(keuze == this.state.name){ // Het was goed
            let newscore = this.state.currentScore + 1
            if( newscore > this.state.highScore){
                this.setState({highScore: newscore})
            }
            this.setState({currentScore: newscore})
        }else{
            this.setState({currentScore: 0})
            alert('Dat was helaas niet goed')
        }
        let randoms = this.randomNames()
        this.setState({
            options: randoms.knoppen,
            name: randoms.randomnaam
        })
    }

    render(){
        return(
            <div className="spel">
                <h3>Highscore: {this.state.highScore} | Score: {this.state.currentScore}</h3>
                <img style={{width: '70%', margin: 'auto', aspectRatio: '1/1', objectFit: 'cover', borderRadius: '5px'}} src={images[this.state.name +".jpeg"]} alt="" />
                <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[0])} label={this.state.options[0]}></Button>
                <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[1])} label={this.state.options[1]}></Button>
                <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[2])} label={this.state.options[2]}></Button>
                <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[3])} label={this.state.options[3]}></Button>
                <Button style={{marginTop: 10}} onClick={() => this.ronde(this.state.options[4])} label={this.state.options[4]}></Button>
            </div>
        )
    }
}