import React, {Component} from "react";

import { Button } from "primereact/button";

export default class Steenpapierbak extends Component<{},{titletext:string}>{
    constructor(props:any){
        super(props)
        this.state = {
            titletext: 'Steen Papier Bak'
        }
    }

    getRandomInt(max:number) {
        return Math.floor(Math.random() * max);
    }

    game(input:number){
        const winmatrix = 
        //steen papier schaar
        [[0, 1, -1], //steen
        [-1, 0, 1], //papier
        [1, -1, 0]] //schaar

        let ai_input = this.getRandomInt(3) 

        let result = winmatrix[ai_input][input]

        if(result === 0){
            this.setState({
                titletext: 'Gelijkspel, speel nog een keer.'
            })
        }else if(result === -1){
            this.setState({
                titletext: 'Verloren, trek een bak.'
            })
        }else if(result === 1){
            this.setState({
                titletext: 'Jij winnaar! Je mag een bak trekken!'
            })
        }
    }
    render(): React.ReactNode {
        return(
            <div className="steenpapierbak">
                <h1>{this.state.titletext}</h1>
                <Button className="steenpapierbak-btn" onClick={() => this.game(0)} label="Steen"></Button>
                <Button className="steenpapierbak-btn" onClick={() => this.game(1)} label="Papier"></Button>
                <Button className="steenpapierbak-btn" onClick={() => this.game(2)} label="Schaar"></Button>
            </div>
        )
    }
}