import React, {Component}from 'react';
import { PrimeIcons, PrimeReactProvider } from 'primereact/api';

import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-dark-blue/theme.css";
import './App.css';

import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { url } from 'inspector';

import Spel from './Spel';
import Steenpapierbak from './Steenpapierbak';

import lustrumlogo from './images/7de lustrumlogo.jpg'
import kaart from './images/kaart.png'

class App extends Component<{},{currentPage:string}>{
  constructor(props:any){
    super(props)
    this.state = {
      currentPage: 'home'
    }
  }

  setPage(page:string){
    this.setState({currentPage: page})
  }

  render(){

    let menuitems = [
      {
        label: 'Home',
        icon: PrimeIcons.HOME,
        url: '/',
        command: () => this.setPage('home')
      },
      {
        label: 'Eetlijst',
        icon: PrimeIcons.LIST,
        url: '/eetlijst'
      },
      {
        label: 'Pilslijst',
        icon: PrimeIcons.CHART_LINE,
        url: 'http://www.vrooman-vrooman.nl/ne47'
      },
      {
        label: 'Mokkenspel',
        icon: PrimeIcons.HEART,
        command: () => this.setPage('mokkenspel')
      },
      {
        label: 'Steen papier bak',
        icon: PrimeIcons.STAR,
        command: () => this.setPage('steenpapierbak')
      },
      {
        label: 'Is het 5v5?',
        icon: PrimeIcons.CLOCK,
        command: () => alert('Het is vast wel ergens 5v5, tijd voor pils!')
      }
    ]

    let pagecontent = <div></div>

    if(this.state.currentPage === 'home'){
      pagecontent = <img className='kaart' src={kaart} alt="" />
    }else if(this.state.currentPage === 'mokkenspel'){
      pagecontent = <Spel></Spel>
    }else if(this.state.currentPage === 'steenpapierbak'){
      pagecontent = <Steenpapierbak></Steenpapierbak>
    }

    return (
      <PrimeReactProvider>
        <Menubar style={{margin: 20}} model={menuitems} />
  
        <div style={{display: 'none'}}>
          <Button onClick={() => {
            alert('Het is vast wel ergens 5v5! Tijd voor pils!')
          }} label='Is het al 5v5?'/>
        </div>
          
        {pagecontent}
        
        <div className='footer'>
          <img src={lustrumlogo} alt="" />
        </div>
      </PrimeReactProvider>
    );
  }
}

export default App;
